import {
  HomeOutlined,
  UserOutlined,
  UserAddOutlined,
  UsergroupAddOutlined,
  SafetyOutlined,
  GiftOutlined,
  OrderedListOutlined,
  DollarCircleOutlined,
  UserSwitchOutlined
} from "@ant-design/icons";

const sidebar = [
  {
    routeName: "frontend.admin.dashboard.index",
    icon: <HomeOutlined />,
    routeParams: {},
  },
  {
    routeName: "frontend.admin.registers.index",
    icon: <UserSwitchOutlined />,
    routeParams: {},
    permissions: {
      "registers": "R",
    }
  },
  {
    routeName: "frontend.admin.payments.index",
    icon: <DollarCircleOutlined />,
    routeParams: {},
    permissions: {
      "payments": "R",
    }
  },
  {
    routeName: "frontend.admin.companies.index",
    icon: <GiftOutlined />,
    routeParams: {},
    permissions: {
      "companies": "R",
    }
  },
  {
    routeName: "frontend.admin.packagesIndex",
    icon: <SafetyOutlined />,
    routeParams: {},
    type: "sub",
    children: [
      {
        routeName: "frontend.admin.packageCategories.index",
        icon: <SafetyOutlined />,
        routeParams: {},
        permissions: {
          "packages": "R"
        }
      },
      {
        routeName: "frontend.admin.packages.index",
        icon: <SafetyOutlined />,
        routeParams: {},
        permissions: {
          "packages": "R"
        }
      }
    ]
  },
  {
    routeName: "frontend.admin.adminsIndex",
    icon: <UserOutlined />,
    routeParams: {},
    type: "sub",
    children: [
      {
        routeName: "frontend.admin.adminGroups.index",
        icon: <UsergroupAddOutlined />,
        routeParams: {},
        permissions: {
          "adminGroups": "R",
        }
      },
      {
        routeName: "frontend.admin.admins.index",
        icon: <UserAddOutlined />,
        routeParams: {},
        permissions: {
          "admins": "R",
        }
      },
      {
        routeName: "frontend.admin.adminPermissions.index",
        icon: <OrderedListOutlined />,
        routeParams: {},
        permissions: {
          "root": "U",
        }
      }
    ]
  }
];

export default sidebar;
